<template>
  <div class="list-info">
    <top-bar :title="'房屋列表'" :left="true"></top-bar>
    <div class="search-top">
      <img :src="require('@/assets/img/search.png')" class="search-icon" />
      <input v-model="searchValue" type="search" value="搜索" placeholder="请输入房屋户或者房主名搜索" class="search-input"
        @keyup.enter="onSearch"/>
      <img :src="require('@/assets/img/clear.png')" class="search-clear" @click="onCancel">
    </div>
    <!-- 筛选栏 -->
    <div class="filterBar">
      <van-row>
        <van-col span="6">
          <p @click="liveShow = !liveShow" :style="{color:liveShow?'#387FF5':'#666666'}">{{ liveValue || '居住信息' }}
            <img :src="require(`@/assets/img/${liveShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="gridShow = !gridShow" :style="{color:gridShow?'#387FF5':'#666666'}">{{ gridValue || '所属网格' }}
            <img :src="require(`@/assets/img/${gridShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="communityLabelCascader = !communityLabelCascader"
             :style="{color:communityLabelCascader?'#387FF5': '#666666' }">
            房屋标签
            <img :src="require(`@/assets/img/${communityLabelCascader?'pull-down-select':'pull-down'}.png`)" alt=""
                 class="pull-icon">
          </p>
        </van-col>
        <van-col span="6">
          <p @click="attrShow = !attrShow" :style="{color:attrShow?'#387FF5':'#666666'}">{{ attrValue || '房屋属性' }}
            <img :src="require(`@/assets/img/${attrShow?'pull-down-select':'pull-down'}.png`)" alt="" class="pull-icon">
          </p>
        </van-col>
      </van-row>
    </div>
    <res-label v-show="communityLabelCascader" :list="userCommunityLabelTree" @selectLabel="selectLabel"></res-label>
    <van-popup v-model="gridShow" position="bottom">
      <grid-select
          :visible.sync="gridShow"
          :orgId="$globalData.userInfo.orgId"
          :multiple="false"
          :title="'网格/微网格'"
          :isSearch="true"
          @cancel="cancelGrid"
          @change="selectGrid"
      ></grid-select>
    </van-popup>

    <!-- 弹出框 -->
    <van-popup v-model="liveShow" round position="bottom" @close="liveChange">
      <van-cascader v-model="liveCascaderValue" title="居住信息" :options="liveList" @close="liveShow = false"
                    @finish="liveShow = false" :field-names="fieldNames" @change="liveConfirm"/>
    </van-popup>
    <van-popup v-model="attrShow" round position="bottom">
      <van-picker show-toolbar :columns="attrList" value-key="label" @cancel="attrShow = !attrShow"
                  @confirm="attrConfirm"/>
    </van-popup>
    <!-- <div class="add-box"
      @mousedown="down"
      @touchstart="down"
      @mousemove="move"
      @touchmove="move"
      @mouseup="end"
      @touchend="end"
      draggable="true"
      ref="fu" 
      @click="goAdd">
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/add1.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div> -->
    <add-box @fuAdd="goAdd"/>
    <div class="houseContent">
      <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                v-if="dataList.length > 0" offset="10">
        <!-- <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ totalCount }}</span>&nbsp;条数据</div> -->
        <div class="totalCount">共有&nbsp;<span>{{totalCount}}</span>&nbsp;条数据</div>
        <div class="list-item" v-for="(item, index) in dataList" :key="index"
             @click="getInfo(
              item.id,item.resCount,item.migrantNum,item.checkType,
            )">
          <div class="top">
            <div class="top-left" :class="{'isOld32': $isOld}">
              {{ (item.householder || '无房主') + ' | ' + (item.householderMobile || '无联系方式') }}
              <img v-if="item.householderMobile" :src="require('@/assets/img/mobile.png')" alt="" class="tel" @click.stop="getRealMobile(item.householderId)"/>
            </div>
            <div class="top-right" v-if="!AdminList.Code">

              <div v-if="item.checkType == 0" class="wait-tag" :class="{'isOld28': $isOld}">待核查</div>
              <div v-if="item.checkType == 1" class="yet-tag" :class="{'isOld28': $isOld}">已核查</div>
            </div>
            <div class="top-right" v-else :class="{'Admins': AdminList.Code}">
              <div  class="wait-tag" :class="{'isOld28': $isOld}">{{item.useTypeName}}</div>
              <span class="wait-tag"  :class="{'isOld28': $isOld}" v-if="(item.useType==2||item.useType==3)&&item.residentsLimit&&AdminList.Code&&(item.resCount>item.residentsLimit)">群租超限</span>
            </div>
          </div>
          <div class="mid">
            <img :src="item.imgUrl || require('@/assets/img/default.png')" class="content-img" />
            <div class="sign" :class="item.resCount == 0 ? 'empty' : item.useType == 2 ? 'rent' : 'live'">
              {{item.resCount == 0 ? '空闲' : item.useType == 2 ? '出租' : '自住'}}
            </div>
            <div class="mid-text">
              <div class="text-top" :class="{'isOld32': $isOld}">
                <div class="left">{{item.subareaName}}：{{item.fullName}}</div>
                <div class="right" v-if="item.labelList.length">
                  <div class="item" v-for="(it,inx) in item.labelList" :key="inx">{{it}}</div>
                </div>
              </div>
              <div class="text-down" :class="{'isOld32': $isOld}">
                <div class="items">共有<span>{{item.resCount}}</span>人</div>
                <div class="items">实有<span>{{item.existNum}}</span>人</div>
                <div class="items">常住<span>{{item.residentNum}}</span>人</div>
              </div>
              <div class="text-down" :class="{'isOld32': $isOld}">
                <div class="items">户籍<span>{{item.registerNum}}</span>人</div>
                <div class="items">流动<span>{{item.migrantNum}}</span>人</div>
              </div>
            </div>
            <van-icon name="arrow" color="#666" class="bottom-icon"/>
          </div>
          <div class="down" :class="{'isOld34': $isOld}" v-if="item.checkType == 0&&!AdminList.Code">
            <div class="inspect" @click.stop="goCheck(item.id,item.checkType,2)">核查</div>
          </div>
          <div class="down"  v-if="AdminList.Code" style="display: flex;flex-direction: row;gap:20px;justify-content:end">
            <div class="inspect1" @click.stop="goRepair(item)">报修</div>
<!--&lt;!&ndash;            本地没有接口&ndash;&gt;-->
            <div class="inspect1" @click.stop="goProblem(item)">问题</div>
<!--            <div class="inspect1" @click.stop="checkInfos(item)">巡查</div>-->
<!--            <div class="inspect1" @click.stop="goVisit(item)">走访</div>-->
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'

import {formatterDate, desensitization} from '@/utils/utils'
import {listComRightLabel,getRealMobile} from "@/utils/common"
import {getImageStream} from '@/utils/index'
import ResLabel from './res-label.vue'
import {mapMutations} from 'vuex'
import gridSelect from '@/components/gridSelection'
import addBox from '@/components/addBox'

export default {
  name: 'house',
  components: {
    topBar,
    ResLabel,
    gridSelect,
    addBox
  },
  data() {
    return {
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
      page: 0,
      limit: 10,
      // hasOn: null, // 1任务开启, 0 任务关闭
      checkType: null, // 1待核查， 0 已核查
      submitData: {
        gridId: '',
        miniGridId: '',
        subarea: '',
        buildingId: '',
        unitId: '',
        houseId: '',
        useType: '',
        labels: []
      },
      notInspect: '',
      liveValue: '',
      gridValue: '',
      attrValue: '',
      searchValue: '',
      finished: false,
      loading: false,
      liveShow: false,
      gridShow: false,
      attrShow: false,
      pullLoading: false,
      communityLabelCascader: false,
      liveList: [],
      gridList: [],
      attrList: [],
      dataList: [],
      userCommunityLabelTree: [],
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children'
      },
      liveCascaderValue: '',
      AdminList:{
        assistId:'',
        Code:'',
        buildingId:'',
        orgId:'',
        type:'',
        userId:'',
      },
      repairAppInfo: {},//报修
      problemAppInfo:{},//问题
      checkInfo:{},//巡查
      visitAppInfo: {},//走访
    };
  },
  computed: {
    desensitization(val) {
      return desensitization(val)
    }
  },
  methods: {
    ...mapMutations,
    down(event) {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move(event) {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove",function (event) {
          if(event.target.localName == 'img') event.preventDefault();
        },{passive:false})
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    getRealMobile(id) {
      getRealMobile(id,function(data) {
        window.location.href = `tel:${data.mobile}`
      })
    },
    // getRealMobile(id) {
    //   this.$http({
    //     url: this.$http.adornUrl('/wxapp/user/getRealMobile'),
    //     method: 'GET',
    //     params: this.$http.adornParams({
    //       id
    //     })
    //   }).then(({data}) => {
    //     if(data && data.code === 0) {
    //       window.location.href = `tel://${data.mobile}`
    //     }else {
    //       this.$toast.fail(data.msg)
    //     }
    //   })
    // },
    getInfo(id, resCount,migrantNum,checkType) {
      console.log("taipi", checkType);
      // let data = {'id':id,'status':status}
      this.$store.commit("setHouseId", id);
      this.$store.commit("setMigrantNum", migrantNum);
      // this.$router.push({
      //   path: "/house-info",
      //   query: {id: id, checkType: checkType},
      // });
      this.$router.push({
        path: '/house-view-details',
        query: {
          id,checkType,
        }
      })
    },
    getDataList() {
      this.page++;
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      if (this.notInspect !== "1") {
        this.notInspect = "0";
      }
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/list"),
        method: "post",
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          gridId: this.submitData.gridId,
          miniGridId: this.submitData.miniGridId,
          subarea: this.submitData.subarea,
          buildingId: this.submitData.buildingId,
          unitId: this.submitData.unitId,
          houseId: this.submitData.houseId,
          useType: this.submitData.useType,
          searchValue: this.searchValue,
          notInspect: this.notInspect,
          labels: this.submitData.labels,
          isCheck: null,
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId,
          userAssistId: this.$globalData.userInfo.assistId || '',
        }),
      }).then(
          ({data}) => {
            if (data.code == 0) {
              this.pullLoading = false;
              if (this.dataList.length == data.page.totalCount) {
                this.finished = true;
              }
              data.page.list.forEach((item) => {
                item.userMobileStr = desensitization(item.userMobile);
                let url = "";
                if (item.resCount == 0) {
                  url = getImageStream("files/wx/images/content/kong.png");
                } else if (item.useType == 2) {
                  url = getImageStream("files/wx/images/content/zu.png");
                } else {
                  url = getImageStream("files/wx/images/content/zhu.png");
                }
                item["url"] = url;
                if(!item.resCount){
                  item.resCount=0
                }
              });
              this.totalCount = data.page.totalCount;
              data.page.list.forEach((data) => {
                data["labelList"] = data.labelShorts
                    ? data.labelShorts.split(",")
                    : "";
              });
              this.dataList = this.dataList.concat(data.page.list);
              // 加载状态结束
              this.loading = false;
              this.$toast.clear();
            }
            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
      );
    },
    init() {
      listComRightLabel((success) => {
        this.userCommunityLabelTree = success;
      }, this.$orgId,this.AdminList.userId);
      // 获取居住信息列表
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/liveWhichTree"),
        method: "get",
        params: this.$http.adornParams({
          communityId: this.$orgId,
        }),
      }).then(
          ({data}) => {
            if (data.code == 0) {
              this.liveList = data.liveWhichTree;
              this.liveList.unshift({label: "所有", value: ""});
            }
          },
          (err) => {
            this.$toast.console.error({message: err});
          }
      )
      this.getDataList()
      // 获取网格列表
      this.$http({
        url: this.$http.adornUrl(`/wxapp/grid/info/jmggGridAndMini`),
        method: "get",
        params: this.$http.adornParams({
          community: this.$globalData.userInfo.orgId,
          loginUserId: this.$globalData.userInfo.userId,
          userAssistId: this.$globalData.userInfo.assistId || '',
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.gridList = data.treeVOS
        }
      })
      // 获取属性列表
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/virtual/useType"),
        method: "get",
      }).then(
          ({data}) => {
            if (data.code == 0) {
              this.attrList = data.useTypes;
              this.attrList.unshift({label: "所有", value: ""});
            }
          },
          (err) => {
            this.$toast.console.error({message: err});
          }
      );
    },
    //核查事件
    goCheck(id,checkType,activeName) {
      this.$store.commit("setHouseId", id);
      this.$router.push({
        path: '/house-view-details',
        query: {
          id,checkType,activeName
        }
      })
      this.$http({
        url: this.$http.adornUrl("/wxapp/building/house/check"),
        method: "post",
        params: this.$http.adornParams({
          id: id,
        }),
      }).then(({data}) => {
        if (data.code == 0) {
          this.page = 0;
          this.dataList = [];
          this.getDataList();
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
    liveChange() {
      this.page = 0;
      this.finished = false;
      this.dataList = [];
      this.getDataList();
      this.liveShow = false;
    },
    liveConfirm(e) {
      // debugger
      this.liveValue = e.selectedOptions[e.selectedOptions.length - 1].label;

      this.submitData.subarea = "";
      this.submitData.buildingId = "";
      this.submitData.unitId = "";
      this.submitData.houseId = "";
      if (e.selectedOptions[e.selectedOptions.length - 1].depth == 0) {
      } else if (e.selectedOptions[e.selectedOptions.length - 1].depth == 1) {
        this.submitData.subarea =
          e.selectedOptions[e.selectedOptions.length - 1].value;
      } else if (e.selectedOptions[e.selectedOptions.length - 1].depth == 2) {
        this.submitData.buildingId =
          e.selectedOptions[e.selectedOptions.length - 1].value;
      } else if (e.selectedOptions[e.selectedOptions.length - 1].depth == 3) {
        this.submitData.unitId =
          e.selectedOptions[e.selectedOptions.length - 1].value;
      } else if (e.selectedOptions[e.selectedOptions.length - 1].depth == 4) {
        this.submitData.houseId =
          e.selectedOptions[e.selectedOptions.length - 1].value;
      }

      this.submitData.subarea = this.submitData.subarea;
      this.submitData.buildingId = this.submitData.buildingId;
      this.submitData.unitId = this.submitData.unitId;
      this.submitData.houseId = this.submitData.houseId;
    },
    // 网格选择
    selectGrid(item) {
      if(item && item.length) {
        this.submitData.gridId = ''
        this.submitData.miniGridId = ''
        let checkedItem = item[0]
        if(checkedItem.depth == 1) {
          this.submitData.gridId = checkedItem.value
        }else {
          this.submitData.miniGridId = checkedItem.value
        }
        this.gridValue = checkedItem.label
        this.page = 0;
        this.dataList = [];
        this.getDataList();
      }
      this.gridShow = false
    },
    cancelGrid() {
      this.submitData.gridId = ''
      this.submitData.miniGridId = ''
      this.gridValue = '所属网格'
      this.page = 0;
      this.dataList = [];
      this.getDataList();
      this.gridShow = false
    },
    attrConfirm(value) {
      this.page = 0;
      this.dataList = [];
      this.attrValue = value.label;
      this.submitData.useType = value.value;
      this.getDataList();
      this.attrShow = false;
    },
    goAdd() {
      this.$router.push("/house-add");
    },
    onPullDownRefresh(e) {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    onSearch() {
      this.page = 0;
      this.dataList = [];
      this.getDataList();
    },
    onCancel() {
      this.page = 0;
      this.dataList = [];
      this.searchValue = "";
      this.getDataList();
    },
    //标签信息改变
    selectLabel(e) {
      this.submitData.labels = e;
      this.communityLabelCascader = false;
      this.onPullDownRefresh();
    },
    //跳转报修
    goRepair(item) {
      let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=repair-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          SM:'saoma',
          userId:item.userId,
          orgId:this.$orgId,
          houseId: item.id+ '',
          appId: this.repairAppInfo.appId,
          appCode: this.repairAppInfo.appCode,
          appName: this.repairAppInfo.appName,
          url: url,
        }
      })
    },
    //走访
    goVisit(item) {
      let url = this.visitAppInfo.pageUrlH5.split('=')[0] + '=visit-add'

      this.$router.push({
        path: '/appSSO',
        query: {
          houseId: item.id,
          SM:'saoma',
          userId: item.userId,
          userName: item.userName,
          appId: this.visitAppInfo.appId,
          appCode: this.visitAppInfo.appCode,
          appName: this.visitAppInfo.appName,
          url: url,
        }
      })
    },
    //跳转问题
    goProblem(item) {
      let url = this.problemAppInfo.pageUrlH5.split('=')[0] + '=problem-add'
      this.$router.push({
        path: '/appSSO',
        query: {
          buildingId:this.buildingId,
          SM:'saoma',
          orgId:this.$orgId,
          houseId: item.id + '',
          appId: this.problemAppInfo.appId,
          appCode: this.problemAppInfo.appCode,
          appName: this.problemAppInfo.appName,
          url: url
          // url: this.problemAppInfo.pageUrlH5,
        }
      })
    },
    //跳转巡查
    checkInfos(item){
      let url = this.checkInfo.pageUrlH5.split('=')[0] + '=patrol-add'

      this.$router.push({
        path: '/appSSO',
        query: {
          placeId: item.id,
          placeType: 2,
          SM:'saoma',
          userId:item.userId,
          orgId:this.$orgId,
          houseId:item.id + '',
          appId: this.checkInfo.appId,
          appCode: this.checkInfo.appCode,
          appName: this.checkInfo.appName,
          url: url,
        }
      })
    },

    getAppInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/geMyAppInfoByCode'),
        method: 'GET',
        params: this.$http.adornParams({
          codes: 'sjgl_bxjl,sjgl_wtjl,sjgl_xcjl,sjgl_sgzf_zfjl',
          orgId: this.$orgId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          data.list.forEach(item => {
            if(item.appCode === 'sjgl_bxjl') {
              // this.isRepairsOpen = true
              this.repairAppInfo = item
            }
            if(item.appCode === 'sjgl_wtjl') {
              // this.isProblemOpen = true
              this.problemAppInfo = item
            }
            if(item.appCode === 'sjgl_xcjl') {
              // this.isVisitOpen = true
              this.checkInfo = item
            }
            if(item.appCode === 'sjgl_sgzf_zfjl') {
              // this.isVisitOpen = true
              this.visitAppInfo = item
            }
          })
          if(data.list && data.list.length) {
            let appId = data.list[0].appId
            // getAppToken(appId)
            // this.$http({
            //   url: this.$http.adornUrl('/wxapp/application/appToken'),
            //   method: 'get',
            //   params: this.$http.adornParams({
            //     appId,
            //   })
            // }).then(({data}) => {
            //   if(data && data.code === 0) {
            //     this.$httpApp({
            //       url: this.$httpApp.adornUrl('/auth/getLoginUser'),
            //       method: 'GET',
            //       params: this.$httpApp.adornParams({
            //         appId,
            //         appToken: data.appToken
            //       })
            //     }).then(({data}) => {
            //       if(data && data.code === 0) {
            //         sessionStorage.setItem('appToken', data.appToken)
            //       }
            //     })
            //   }
            // })
          }
        }
      })
    },
  },
  // activated() {
  //   this.page = 0;
  //   this.limit = 10;
  //   this.totalCount = 0;
  //   this.finished = false;
  //   this.loading = false;
  //   this.dataList = [];

  // },
  created() {
    console.log(this.$route.query)
    if(this.$route.query.Code=='admin'){
      this.AdminList.Code=this.$route.query.Code
      this.submitData.buildingId=this.$route.query.buildingId
      this.AdminList.buildingId=this.$route.query.buildingId
      this.AdminList.orgId=this.$route.query.orgId
      this.submitData.useType=this.$route.query.type
      this.$orgId=this.$route.query.orgId
      this.AdminList.userId=this.$route.query.userId
      this.AdminList.assistId=this.$route.query.assistId
      this.$globalData.userInfo.assistId=this.$route.query.assistId
      this.$globalData.userInfo.userId=this.$route.query.userId
      this.$globalData.userInfo.orgId=this.$route.query.orgId
      this.$isOld={}
      sessionStorage.setItem("AdminList", JSON.stringify(this.AdminList));
      this.getAppInfo()
    }
    this.$toast.loading({
      duration: 0,
      message: "加载中...",
      forbidClick: true,
    });
    this.notInspect = this.$globalData.notInspect
    console.log('create')
    this.init()
  },
  activated () {
    console.log('active')
    let globalSearchValue = this.$route.query
    if (globalSearchValue.attrValue) {
      this.attrValue = globalSearchValue.attrValue
    } else {
      this.attrValue = ''
    }
    if (globalSearchValue.useType) {
      this.submitData.useType = globalSearchValue.useType
    } else {
      this.submitData.useType = ''
    }
    if (globalSearchValue.orgId && this.$globalData.orgId != globalSearchValue.orgId) {
      this.submitData.subarea = globalSearchValue.orgId
    } else {
      this.submitData.subarea = ''
    }
    if (globalSearchValue.unitId) {
      this.submitData.unitId = globalSearchValue.unitId
    } else if (globalSearchValue.buildingIds) {
      this.submitData.buildingId = globalSearchValue.buildingIds
    } else {
      this.submitData.unitId = ''
      this.submitData.buildingId = ''
    }
    if (globalSearchValue.obuhName) {
      this.liveValue = globalSearchValue.obuhName
    } else {
      this.liveValue = ''
    }
    this.page = 0;
    this.dataList = [];
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.list-item {
  .mid {
    img{
      border-radius: 10px;
    }
    .mid-text {
      .text-top {
        .left {
          margin-right: 32px;
        }
        .right {
          display: flex;
          .item {
            padding: 5px 10px;
            font-size: 24px;
            font-family: 'PingFang';
            font-weight: 500;
            color: #4581F8;
            background: rgba(69,129,248,0.12);
            border-radius: 4px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
</style>
<style scoped>
.item_btn {
  float: right;
  font-weight: bold;
  border: none;
  text-align: center;
  line-height: 64px;
  padding: 0 40px;
  height: 64px;
  font-size: 24px;
  border-radius: 32px;
  margin-left: 20px;
}

.van-tag-war {
  justify-content: space-around;
  width: 120px;
  height: 40px;
  text-align: center;
  padding: 0 auto;
  background-color: rgb(255, 161, 106, 0.12);
  color: rgb(255, 161, 106);
}

.van-tag-pri {
  justify-content: space-around;
  width: 120px;
  height: 40px;
  text-align: center;
  padding: 0 auto;
  background-color: rgb(25, 137, 250, 0.12);
  color: rgb(25, 137, 250);
}
.inspect {
  width: 112px;
  height: 60px;
  background: #4581F8;
  border-radius: 8px;
  font-size: 28px;
  font-family: 'PingFang Bold';
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 60px;
}
.inspect1 {
  width: 152px;
  height: 60px;
  background: #4581F8;
  border-radius: 8px;
  font-size: 28px;
  font-family: 'PingFang Bold';
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 60px;
}
.Admins{
  display:flex !important;
  gap:10px;
  flex-direction: row;
}
</style>
